import React, { useState } from 'react';
import './Header.scss';
import logo from '../Media/whitelogo.svg';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [activeSection, setActiveSection] = useState('Overview');
    const location = useLocation();
    const [showProducts, setShowProducts] = useState(false);
    const [showService, setShowService] = useState(false);
    const [showWhatWeDo, setShowWhatWeDo] = useState(false);
    // const location = useLocation();

    const toggleProducts = () => {
        setShowProducts(!showProducts);
    };
    const toggleService = () => {
        setShowService(!showService);
        setShowProducts(false); // Ensure only one dropdown is open at a time
    };
    const toggleWhatWeDo = () => {
        setShowWhatWeDo(!showWhatWeDo);
    };
    const isActive = (path) => location.pathname === path;
    const isWhatWeDoActive = location.pathname.startsWith("/whatWeDo");

    const handleSectionClick = (section) => {
        // if (activeSection !== section) {
            console.log(`Switching to ${section}`);
            setActiveSection(section);
        // }
    };
   
    return (
        <>
            <nav>
                <div className="wrapper">
                    <div className="logo">
                        <a href="/" target="_self">
                            <img className="logo" src={logo} alt="Logo" />
                        </a>
                    </div>
                    <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn" />
                <ul className="nav-links">
                    <label htmlFor="close-btn" className="btn close-btn">
                        <i className="pi pi-times"></i>
                    </label>

                    {/* <ul className="nav-links"> */}
                        <li className={`what-we-do ${isWhatWeDoActive ? 'activeMenu' : ''}`}>
                            <a href="#" className={`desktop-item ${isWhatWeDoActive ? 'activeMenu' : ''}`}>What We Do</a>
                        <input type="checkbox" id="showMega" />
                        <label htmlFor="showMega" className="mobile-item sub-section" onClick={toggleWhatWeDo}>
                            <div className="what-we-do-text">What We Do</div>
                            <div className="what-we-do-symbol">{showWhatWeDo ? '-' : '+'}</div>
                        </label>
                            <div className="mega-box">
                                <div className="contents">
                                    <h2 className="head-trust">Building On Trust<span className="trust-dot">.</span></h2>
                                    <div className="row">
                                        <p
                                            className={`row-item ${activeSection === 'Overview' ? 'active' : ''}`}
                                            onClick={() => handleSectionClick('Overview')}
                                        >
                                            Overview
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p
                                            className={`row-item ${activeSection === 'Service' ? 'active' : ''}`}
                                            onClick={() => handleSectionClick('Service')}
                                        >
                                            Service
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p
                                            className={`row-item ${activeSection === 'Products' ? 'active' : ''}`}
                                            onClick={() => handleSectionClick('Products')}
                                        >
                                            Products
                                        </p>
                                    </div>
                                    <div className="row content-display">
                                        {/* Directly render the content here based on activeSection */}
                                        {activeSection === 'Overview' && (
                                            <div className="content">
                                                <p>
                                                    Our core business operation is to optimize your business through technology.
                                                    We explore a wide range of capabilities that meet clients' needs.
                                                </p>
                                            </div>
                                        )}
                                        {activeSection === 'Service' && (
                                               <div className="desktop-item">
                                            <ul className="submenus">
                                                <li><a href="#">Enterprise Solutions</a></li>
                                                <li><a href="#">Mobile App Development</a></li>
                                                <li><a href="#">Product & Customer Support</a></li>
                                                <li><a href="#">Web Design & Development</a></li>
                                                <li><a href="#">Consulting Services</a></li>
                                                <li><a href="#">Digital Marketing</a></li>
                                            </ul>
                                            </div>
                                        )}
                                    
                                    
                                    <header className='mobile-item sub-section' onClick={toggleProducts}>
                                        <div className='product-text'>Services</div>
                                        <div className='product-symbol'>{showProducts ? '-' : '+'}</div>
                                    </header>

                                    {
                                        showProducts && (
                                            <div className="mobile-item">
                                                <ul className={`mega-link ${showProducts ? 'show' : ''}`}>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Enterprise Solutions</a></li>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Mobile App Development</a></li>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Product & Customer Support</a></li>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Web Design & Development</a></li>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Consulting Services</a></li>
                                                    <li className={isActive("#") ? "active" : ""}><a href="#">Digital Marketing</a></li>
                                                </ul>
                                            </div>
                                        )
                                    }
                             
                                        {activeSection === 'Products' && (
                                            <ul className="submenuse">
                                                <div className="sub-head">
                                                    <li><a href="/whatWeDo/VCheck">VCheck</a></li>
                                                    <li><a href="/whatWeDo/Exaan">Exaan</a></li>
                                                    <li><a href="/whatWeDo/Acaia">Acaia</a></li>
                                                </div>
                                            </ul>
                                        )}
                                       <header className='mobile-item sub-section' onClick={toggleService}>
                                        <div className='product-text'>Products</div>
                                        <div className='product-symbol'>{showService ? '-' : '+'}</div>
                                    </header>
                                    {
                                        showService && (
                                            <div className="mobile-item">
                                                <ul className={`mega-link ${showService ? 'show' : ''}`}>
                                                    <li className={isActive("/whatWeDo/VCheck") ? "active" : ""}><a href="/whatWeDo/VCheck">VCheck</a></li>
                                                    <li className={isActive("/whatWeDo/Exaan") ? "active" : ""}><a href="/whatWeDo/Exaan">Exaan</a></li>
                                                    <li className={isActive("/whatWeDo/Acaia") ? "active" : ""}><a href="/whatWeDo/Acaia">Acaia</a></li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={isActive("/whoWeAre") ? "active" : ""}><a href="/whoWeAre">Who We Are</a></li>
                        <li className={isActive("/blog") ? "active" : ""}><a href="/blog">Insights</a></li>
                        <li className={isActive("/careers") ? "active" : ""}><a href="/careers">Careers</a></li>
                        <li className={isActive("/contactus") ? "active" : ""}><a href="/contactus">Contact Us</a></li>
                    {/* </ul> */}
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><i className="pi pi-bars"></i></label>
                </div>
                
            </nav>
        </>
    );
};

export default Header;
