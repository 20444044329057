
import React from 'react';
import ContactUs from "../src/ReachUs/ContactUs";
import Forbidden from "./ForbiddenPage/Forbidden";
import './App.css';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import ReactGA from 'react-ga';
import CareerPage from './ReachUs/CareerPage/CareerPage';
import FooterPage from './FooterPage/FooterPage';
import BlogView from './BlogPage/BlogView/BlogView';
import ProductPage from './WhatWeDo/ProductPage';
import WhoWeAre from './WhoWeAre/WhoWeAre';
import Home from './Home/Home';
import Insights from './BlogPage/Insights';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';



const TRACKING_ID = "UA-276699714-1"; //our tracking id
ReactGA.initialize(TRACKING_ID);

export default function App() {
  window.scrollTo(0,0);
  return (
    <div>
    <BrowserRouter>
    
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/blog" element={<Insights />} />
        <Route path="/blog/:id" element={<BlogView />} />
        <Route path="/whoWeAre" element={<WhoWeAre />} />
        <Route path="/whatWeDo/:id" element={<ProductPage />} />
        <Route path='/termsandconditions' element={<TermsAndConditions/>}/>
        <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>

        <Route path="/*" element={<ForbiddenWrapper />} />

      </Routes>

      <FooterPage/>
      
    </BrowserRouter>
    {/* <CustomCursor/> */}
    </div>
  )
}

// function FooterWrapper() {
//   const location = useLocation();
//   const isForbiddenPage = location.pathname.startsWith('/forbidden');

//   if (isForbiddenPage || location.pathname === '/*') {
//     return null;
//   }

//   return <Footer />;
// }

function ForbiddenWrapper() {
  const location = useLocation();
  const isForbiddenPage = location.pathname.startsWith('/forbidden');

  if (isForbiddenPage) {
    return <Forbidden />;
  }

  return <Navigate to="/forbidden" />; 
}