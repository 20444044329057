import React from 'react';
import './BannerSection.scss';

const BannerSection = () => {
    return (
        <div className="banner-section">
            <div className="banner-content">
                <h1>First Step In Your Digital Transformation<span>.</span></h1>
            </div>
        </div>
    );
};

export default BannerSection;