import { useParams } from 'react-router-dom';
import Header from '../Header/Header';
import './ProductPage.scss';

import banner1 from "../Assets/WhatWeDo/portrait-indigenous-person-integrating-society.webp";
import banner2 from "../Assets/WhatWeDo/business-person-futuristic-business-environment.webp";
import banner3 from "../Assets/WhatWeDo/business-person-futuristic-business-environment (1).webp";

import { Skeleton } from 'primereact/skeleton';
import { FaRegCheckCircle } from 'react-icons/fa';

import vCheckLogo from "../Assets/WhatWeDo/vcheck-logo.svg";
import freeSecure from "../Assets/WhatWeDo/freeSecure.svg";
import easyIntegration from "../Assets/WhatWeDo/easyIntegration.svg";
import privacyAssured from "../Assets/WhatWeDo/privacyAssured.svg";

import exaanLogo from "../Assets/WhatWeDo/exaanLogo.svg";
import forex from "../Assets/WhatWeDo/forex.svg";
import regulatoryCompliance from "../Assets/WhatWeDo/regulatoryCompliance.svg";
import treasury from "../Assets/WhatWeDo/treasury.svg";
import accounting from "../Assets/WhatWeDo/accounting.svg";
import remittance from "../Assets/WhatWeDo/remittance.svg";

import acaiaLogo from "../Assets/WhatWeDo/acaiaLogo.svg";
import dataPrivacy from "../Assets/WhatWeDo/dataPrivacy.svg";
import customSanctionList from "../Assets/WhatWeDo/customSanctionList.svg";
import proactiveAudit from "../Assets/WhatWeDo/proactiveAudit.svg";
import amlChecks from "../Assets/WhatWeDo/amlChecks.svg";
import potentRiskRating from "../Assets/WhatWeDo/potentRiskRating.svg";

import ctaImage from "../Assets/WhatWeDo/portrait-woman-customer-service-worker.webp";

const products = [
    {
        id: "VCheck",
        name: 'VCheck',
        image: banner1,
        content: "Secure your files from malware!",
        subContent: "Detect malware and viruses in files using our free API keys.",
        productName: 'VCheck',
        description: "VCheck offers hassle-free malware scanning. Our service is free and secure, and respects your privacy by deleting files automatically after 24 hours. Whether you're an individual or a company, integrating our robust upload and download APIs into your website is simple and effective, ensuring your files are always protected.",
        productLogo: vCheckLogo,
        features: [
            { title: 'Free And Secure', icon: freeSecure, active: false },
            { title: 'Easy Integration', icon: easyIntegration, active: true },
            { title: 'Privacy Assured', icon: privacyAssured, active: false },
        ],
        CTAContent: "Register Now To Get Your Unique API Keys.",
    },
    {
        id: "Exaan",
        name: 'Exaan',
        image: banner2,
        content: 'A Cosmic Financial Solutions',
        subContent: "Seamless transaction and collaborative ecosystem.",
        productName: "Exaan",
        description: "As an all-in-one financial management solution, Exaan creates an impact in every domain of the financial service industry. Exaan is designed to help financial services institutions gain operational efficiency, enhance end-customer experience, and embrace innovative technologies.",
        subdescription: "Made available on the cloud, white labeled, also offered as SaaS, and built on a strong digital core, Exaan is visioned on creating seamless digital financial journeys, the products offer advanced breadth and depth of functionality and are built on a modern, deep technology stack.",
        productLogo: exaanLogo,
        features: [
            { title: 'Forex', icon: forex, active: false },
            { title: 'Regulatory Compliance', icon: regulatoryCompliance, active: true },
            { title: 'Treasury', icon: treasury, active: false },
            { title: 'Accounting', icon: accounting, active: true },
            { title: 'Remittance', icon: remittance, active: false },
        ],
        CTAContent: "A Solution That is Loved In Every Universe.",
        CTADescription: "It's not strange to deserve it."
    },
    {
        id: "Acaia",
        name: 'Acaia',
        image: banner3,
        content: 'Anti Money Laundering Suite',
        subContent: "Acaia has you covered when it comes to regulatory compliance",
        productName: "An Absolute AML Solution",
        description: "Financial institutions can be at ease when it comes to regulatory compliance. Acaia is an AML suite to enhance your defense against Fraud & Money Laundering. Acaia ensures that financial institutions derive high performance for their mission to prevent illicit money transfers.",
        productLogo: acaiaLogo,
        features: [
            { title: 'Data Privacy', icon: dataPrivacy, active: false },
            { title: 'Custom Sanction list', icon: customSanctionList, active: true },
            { title: 'Proactive Audit', icon: proactiveAudit, active: false },
            { title: 'AML Checks', icon: amlChecks, active: true },
            { title: 'Potent Risk Rating', icon: potentRiskRating, active: false }
        ],
        link: 'https://acaia.us/',
        CTAContent: "Stress-free compliance is within reach.",
        CTADescription: "Partner with us to be fully compliant through AI."
    },
];

const ProductPage = () => {
    const { id } = useParams();
    if (!Array.isArray(products)) {
        return <div>Error: products is not an array</div>;
    }

    const product = products.find(item => item.id === id);

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <>
            <Header />

            <div className="product-pages">
                <img src={product?.image} alt={product?.productName} className="product-page-img" />
                <div className="product-page-text">
                    <h2>{product?.content}<span>.</span></h2>
                    <p>{product?.subContent}</p>
                </div>
            </div>

            <div className="product-contents-section">
                <div className="product-square-section">
                    <h2>{product?.productName}</h2>
                    <p>{product?.description}</p>

                    <div className="product-square-content">
                        <div className="product-square-content-top-section">
                            <div className="product-square-content-top-section-left">
                                <span className="red-circle"></span>
                                <span className="orange-circle"></span>
                                <span className="green-circle"></span>
                            </div>

                            <img src={product?.productLogo} alt={product?.productName} />
                        </div>

                        <div className="features">
                            {product?.features.map((feature, index) => (
                                <div className="feature-section n">
                                    <div key={index} className={`feature-card ${feature.active ? 'active' : ''}`}>
                                        <div className="iconn" style={{
                                            backgroundColor: index % 2 === 0 ? '#FFFFFF33' : '#252525',
                                        }}>
                                            <img src={feature.icon} alt={`${feature.title} icon`} />
                                        </div>
                                        <div className="titles">{feature.title}</div>
                                    </div>

                                    <div className="statuss">
                                        <FaRegCheckCircle />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="box-1">
                            <div className="product-square-content-skeleton">
                                <Skeleton className="mb-3 skeleton1"></Skeleton>
                                <Skeleton className="mb-3 skeleton2"></Skeleton>
                                <Skeleton className="skeleton3"></Skeleton>
                            </div>

                        </div>

                        <div className="box-2">
                            <div className="product-square-content-skeleton">
                                <Skeleton className="mb-3 skeleton1"></Skeleton>
                                <Skeleton className="mb-3 skeleton2"></Skeleton>
                                <Skeleton className="skeleton3"></Skeleton>
                            </div>
                        </div>

                        <div className="box-3">
                            <div className="product-square-content-skeleton">
                                <Skeleton className="mb-3 skeleton1"></Skeleton>
                                <Skeleton className="mb-3 skeleton2"></Skeleton>
                                <Skeleton className="skeleton3"></Skeleton>
                            </div>

                            <div className="product-square-content-skeleton"></div>
                        </div>

                        <div className="box-3">
                            <div className="product-square-content-skeleton"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="call-to-action">
                <div className="cta-content">
                    <h2>{product?.CTAContent}</h2>
                    <p>{product?.CTADescription}</p>
                    <div className="cta-buttons">
                        <button className="cta-btn contact"><a href="/contactUs">CONTACT US</a></button>

                        {product?.name === "Exaan" || product?.name === "VCheck" ? ("") : (
                            <button className="cta-btn visit">
                                <a href={product?.link} target='_blank' rel="noreferrer">
                                    VISIT US
                                </a>
                            </button>
                        )}
                    </div>
                </div>

                <div className="cta-image">
                    <img src={ctaImage} alt="Customer Service" />
                </div>
            </div>
        </>
    );
};

export default ProductPage;
