import React, { useEffect, useState } from "react";
import "./WhatWeDo.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import DataAnalytics from "../../Assets/Home/DataAnalytics.webp";
import CyberSecurity from "../../Assets/Home/CyberSecurity.webp";
import AI from "../../Assets/Home/AI.webp";
import SustainabilityService from "../../Assets/Home/SustainabilityServices.webp";
import EnterpriseSolution from "../../Assets/Home/EnterpriseSolutions.webp";
import c2c from "../../Assets/Home/c2c.webp";

const WhatWeDo = () => {
    const [index, setIndex] = useState(0);

    const getViewMode = (width) => {
        if (width <= 767) return 1;
        if (width >= 768 && width <= 1058) return 2;
        return 3;
    };

    const [viewMode, setViewMode] = useState(getViewMode(window.innerWidth));

    const data = [
        { image: DataAnalytics, title: "Data Analytics" },
        { image: CyberSecurity, title: "Cyber Security" },
        { image: AI, title: "Artificial Intelligence" },
        { image: SustainabilityService, title: "Sustainability Service" },
        { image: EnterpriseSolution, title: "Enterprise Solution" },
        { image: c2c, title: "C2C Staffing" },
    ];

    useEffect(() => {
        const handleResize = () => {
            setViewMode(getViewMode(window.innerWidth));
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + viewMode) % data.length);
    };

    const handlePrev = () => {
        setIndex(
            (prevIndex) =>
                (prevIndex - viewMode + data.length) % data.length
        );
    };

    const visibleScreens = data
        .slice(index, index + viewMode)
        .concat(
            index + viewMode > data.length
                ? data.slice(0, (index + viewMode) % data.length)
                : []
        );

    return (
        <div className="whatwedo-container">
            <button className="whatwedo-btn">What We Do</button>
            <h2>Level Up Your Business With Our Innovative Solutions</h2>
            <div className="controls">
                <button onClick={handlePrev} className="prev">
                    <FaArrowLeft />
                </button>

                <div className="whatwedo-wrapper">
                    {visibleScreens.map((data, i) => (
                        <div key={i} className="whatwedo">
                            <img src={data.image} alt={data.title} />
                            <h3>{data.title}</h3>
                        </div>
                    ))}
                </div>

                <button onClick={handleNext} className="next">
                    <FaArrowRight />
                </button>
            </div>
        </div>
    );
};

export default WhatWeDo;