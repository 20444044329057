import PrivacyTab from "./PrivacyTab/PrivacyTab";
import Header from "../Header/Header";

const PrivacyPolicy=()=>{
    return(
        <>
            <Header/>
            <PrivacyTab/>
        </>
    )
}

export default PrivacyPolicy;