import Header from "../Header/Header"
import SideTab from "./SideTab/SideTab";


const TermsAndConditions=()=>{
    return(
        <>
        <Header/>
       <SideTab/>
        </>
    )
}

export default TermsAndConditions;